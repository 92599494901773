/* responsiveUspCountdown */
define(['$console', '$window'], ($console, $window) => () => {
  const comp = {
    element: null,
    times: [],
    isValid: true,
    cutoffTime: null,
    interval: null
  };

  const _attr = {
    timezoneOffset: 'data-timezone-offset',
    timeCutoff: 'data-time-cutoff'
  };

  /**
   * Init
   *
   * @param {HTMLElement} element
   */
  comp.init = function(element) {
    comp.element = element;

    comp.build();
    comp.show();
  };

  /**
   * Builds the cutoffdate.
   */
  comp.build = function() {
    const timeRegex = /^(\d|\d\d):(\d\d)$/;
    const cutoffs = comp.element.getAttribute(_attr.timeCutoff);
    const timezoneOffset = parseFloat(comp.element.getAttribute(_attr.timezoneOffset));

    comp.times = cutoffs.split(',').map(time => {
      if (!timeRegex.test(time)) {
        return null;
      }

      const groups = timeRegex.exec(time);
      const hour = parseInt(groups[1]);
      const minute = parseInt(groups[2]);

      if (isNaN(hour) || isNaN(minute) || hour < 0 || hour > 23 || minute < 0 || minute > 59) {
        return null;
      }

      return { hour, minute };
    });

    comp.isValid = !isNaN(timezoneOffset) && !comp.times.some(t => t === null);

    if (!comp.isValid) {
      return false;
    }

    const now = comp.newDate();
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth();
    const day = now.getUTCDay();
    const date = now.getUTCDate();
    const hour = comp.times[day].hour;
    const minute = comp.times[day].minute;
    const newDate = new Date(Date.UTC(year, month, date, hour, minute));

    comp.cutoffTime = new Date(newDate.getTime() + Math.floor(timezoneOffset * 3600 * 1000));

    return true;
  };

  /**
   * Shows countdown and creates interval to update text.
   */
  comp.show = function() {
    if (!comp.isValid) {
      $console.info('Invalid configuration for responsiveUspCountdown timer. Please check widget setup if you expect the countdown to appear.');
      return;
    }

    const now = comp.newDate();
    const diff = comp.cutoffTime.getTime() - now.getTime();

    // don't show if current date is after cutoff date
    if (diff < 0) {
      return;
    }

    comp.updateText();
    comp.interval = $window.setInterval(comp.updateText, 1000);
  };

  comp.updateText = function() {
    const now = comp.newDate();
    const diff = comp.cutoffTime.getTime() - now.getTime();

    if (diff > 0) {
      const hours = comp.format(Math.floor(diff / 3600 / 1000) % 24);
      const minutes = comp.format(Math.floor(diff / 60 / 1000) % 60);
      const seconds = comp.format(Math.floor(diff / 1000) % 60);

      comp.element.innerText = `${hours}:${minutes}:${seconds}`;
    } else {
      comp.element.innerText = '00:00:00';
      $window.clearInterval(comp.interval);
    }
  };

  /**
   * Formats a number as %2f.
   *
   * @param {Number} number
   * @returns {String} formatted string
   */
  comp.format = function(number) {
    return ('0' + number).slice(-2);
  };

  /**
   * Return a new date. Purely used for preventing flaky tests.
   */
  comp.newDate = function() {
    return new Date();
  };

  return comp;
});
